<template>
<div class="main">
  <Tree service="/sys/sysOrganization/dept" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "account",
            title: "账户名称",
            minWidth: 160,
            align: "left",
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: '请输入账户名称'
              },
            },
          },
          {
            field: "name",
            title: "用户姓名",
            minWidth: 160,
            align: "left",
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: '请输入用户姓名'
              },
            },
          },
          {
            field: "sex",
            title: "性别",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "sex",
              props: {
                placeholder: '请选择性别'
              },
            },
          },
          {
            field: "phone",
            title: "联系电话",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 11,
                placeholder: '请输入联系电话'
              },
            },
          },
          {
            field: "roleIds",
            title: "角色",
            minWidth: 160,
            editRender: {
              name: "$VxeSelectRole",
              props: {
                clearable: true,
                placeholder: '请选择所属角色'
              },
            },
          },
          {
            field: "status",
            title: "状态",
            width: 100,
            editRender: {
              name: "$select",
              options: [{
                  label: '正常',
                  value: "0"
                },
                {
                  label: '锁定',
                  value: "1"
                },
                {
                  label: '过期',
                  value: "2"
                },
              ],
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
            },
          },
        ],
        editConfig: {},
        menuConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "基本信息",
                position: "left"
              }
            },
          },
          {
            field: "organizationId",
            title: "所属上级",
            span: 12,
            itemRender: {
              name: "$VxeSelectParent",
              expandAll: true,
              props: {
                placeholder: "请选择所属上级",
              },
              events: {
                click: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.id;
                  data["parentId"] = node.type == "dept" ? node.parentId : node.id;
                  data['organizationId'] = node.id;
                  data.roleIds = [];
                },
              }
            },
          },
          {
            field: 'roleIds',
            title: '所属角色',
            span: 12,
            itemRender: {
              name: "$VxeSelectRole",
              props: {
                clearable: true,
                placeholder: '请选择所属角色'
              },
            }
          },
          {
            field: "name",
            title: "用户姓名",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入用户姓名",
              },
            },
          },
          {
            field: "sex",
            title: "性别",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "sex",
              props: {
                placeholder: "请选择性别",
              },
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "账户信息",
                position: "left"
              }
            },
          },
          {
            field: "account",
            title: "账户名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入账户名称",
              },
            },
          },
          {
            field: "status",
            title: "是否启用",
            span: 12,
            itemRender: {
              name: "$select",
              options: [{
                  label: '正常',
                  value: "0"
                },
                {
                  label: '锁定',
                  value: "1"
                },
                {
                  label: '过期',
                  value: "2"
                },
              ],
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          // {
          //   field: 'password',
          //   title: '设置密码',
          //   span: 12,
          //   itemRender: {
          //     name: '$input',
          //     props: {
          //       type: "password",
          //       placeholder: '请输入账户密码',
          //       maxlength: "26"
          //     }
          //   }
          // },
          // {
          //   field: 'repeatPassword',
          //   title: '确认密码',
          //   span: 12,
          //   itemRender: {
          //     name: '$input',
          //     props: {
          //       type: "password",
          //       placeholder: '请确认账户密码',
          //       maxlength: "26"
          //     }
          //   }
          // },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "其他",
                position: "left"
              }
            },
          },
          {
            field: "nickName",
            title: "用户昵称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入用户昵称",
              },
            },
          },
          {
            field: "phone",
            title: "联系电话",
            span: 12,
            itemRender: {
              name: "$VxePhoneInput",
              props: {
                placeholder: "请输入联系电话",
              },
            },
          },
          {
            field: 'remark',
            title: '备注',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入备注内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          organizationId: [
            { required: true, message: "请选择所属上级" },
          ],
          name: [
            { required: true, message: "请输入用户名称" },
          ],
          sex: [
            { required: true, message: "请选择性别" },
          ],
          account: [
            { required: true, message: "请输入账户名称" },
          ],
          // password: [
          //   { required: true, message: '请输入账户密码' },
          //   { min: 6, max: 26, message: '长度在 6 到 26 个字符' }
          // ],
          // repeatPassword: [
          //   { required: true, message: '请确认账户密码' },
          //   {
          //     validator({ data, itemValue, callback }, ) {
          //       if (itemValue != data.password) {
          //         return new Error('两次输入密码不一致!');
          //       }
          //     },
          //     trigger: 'change'
          //   }
          // ],
          roleIds: [
            { required: true, message: "请选择用户角色" },
            {
              validator({ itemValue }) {
                if (itemValue && !itemValue.length) {
                  return new Error('请选择用户角色!');
                }
              },
              trigger: 'change'
            }
          ],
          status: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          organizationId: "",
          roleIds: [],
          name: "",
          code: "",
          sex: "1",
          orderCode: "0",
          status: "0",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "用户姓名",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入用户姓名",
              },
            },
          },
          {
            field: "status",
            title: "状态",
            itemRender: {
              name: "$select",
              options: [{
                  label: '正常',
                  value: "0"
                },
                {
                  label: '锁定',
                  value: "1"
                },
                {
                  label: '过期',
                  value: "2"
                },
              ],
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: "",
          status: "",
        },
      },
    };
  },
  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "organizationId");

      this.formOptions.data['organizationId'] = node.id;
      this.formOptions.data['parentId'] = node.type == "dept" ? node.parentId : node.id;
      let { xGrid } = this.$refs;
      xGrid.searchOptions.data["organizationId"] = node.id;
      xGrid.getLists();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
